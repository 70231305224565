import qs from "query-string";
import api from "@/api";
import { ActionTree } from "vuex";
import orderBy from "lodash.orderby";
import deepEqual from "fast-deep-equal";
import { AudioItemsState } from "./types";
import { RootState } from "../../types";
import stageconfig from "@/store/modules/stageconfig";
import redirect from "@/utils/redirect";
import { addSeconds } from "date-fns";
import axios from "axios";

// Uncomment to test polling mechanism
// import mockedPolledItems from "./mockedPolledItems";

const CONTEXT_BUFFER = 50; // return more transcript characters for additional context

const { post, patch } = api;

const pollInterval = 30000;
let nextPoll;

async function queryAudioItems({
  authJWT,
  filterRules,
  fragmentSize,
  searchContinuationFields,
  sortSpecs,
  view
}) {
  const searchRequest = {
    url: `audioitems?${qs.stringify({ view })}`,
    payload: {
      ...(sortSpecs ? { sortSpecs } : {}),
      ...(searchContinuationFields ? { searchContinuationFields } : {}),
      ...(filterRules ? { filterRules } : {}),
      highlightSpecs: [
        {
          field: "transcript",
          highlighterType: "unified",
          fragmentSize: fragmentSize + CONTEXT_BUFFER,
          ellipsis: "(…)",
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "description",
          highlighterType: "unified",
          fragmentSize: fragmentSize + CONTEXT_BUFFER,
          ellipsis: "(…)",
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "headline",
          highlighterType: "unified",
          numberOfFragments: 0,
          ellipsis: null,
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "person",
          highlighterType: "unified",
          numberOfFragments: 0,
          ellipsis: null,
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        }
      ]
    },
    authJWT,
  };

  return post(searchRequest);
}

async function queryAudioItemsForGuid({ view, guid, authJWT, filterRules }) {
  return post({
    url: `audioitems?${qs.stringify({ view })}`,
    payload: {
      filterRules: [
        {
          type: "FILTER_GUID",
          values: guid
        },
        ...(filterRules?.length ? filterRules : [])
      ],
      highlightSpecs: [
        {
          field: "transcript",
          numberOfFragments: 0,
          highlighterType: "unified",
          ellipsis: "(…)",
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "headline",
          highlighterType: "unified",
          numberOfFragments: 0,
          ellipsis: null,
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "description",
          highlighterType: "unified",
          numberOfFragments: 0,
          ellipsis: null,
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        },
        {
          field: "person",
          highlighterType: "unified",
          numberOfFragments: 0,
          ellipsis: null,
          postTag: "</mark>",
          preTag: "<mark class='highlight'>"
        }
      ]
    },
    authJWT,
  });
}

let latestVersionCreatedDate = new Date().toISOString();
const pollData = async ({
  filterRules,
  view,
  sortSpecs,
  searchContinuationFields,
  fragmentSize,
  commit,
  rootGetters,
  rootState
}) => {
  try {
    const authJWT = rootGetters["auth/validateAndRetrieveToken"]();
    const { ssoURL } = rootState.stageconfig.config;

    if (!authJWT) {
      redirect(`${ssoURL}/login?service=${window.location.origin}`);
    }

    const rules = filterRules?.filterRules || [];
    const pollResponse = await queryAudioItems({
      authJWT,
      filterRules: [
        ...rules,
        {
          type: "FILTER_VERSION_CREATED",
          values: [latestVersionCreatedDate, ""]
        }
      ],
      fragmentSize,
      searchContinuationFields,
      sortSpecs,
      view
    });

    // Uncomment to test polling mechanism
    /* pollResponse.audioItems = pollResponse.audioItems.concat(
      mockedPolledItems()
    ); */

    const newAudioItems = pollResponse.audioItems.map(audioitem => ({
      ...audioitem,
      polled: true
    }));
    if (
      newAudioItems.length &&
      deepEqual(rules, rootState.filterRules.filterRules)
    ) {
      const { version_created } = orderBy(
        newAudioItems,
        (audioItem: Definitions.AudioItem) =>
          new Date(audioItem.version_created),
        "desc"
      )[0];
      latestVersionCreatedDate = addSeconds(
        new Date(version_created),
        1
      ).toISOString();
      // mutate the list to place newer items in the correct order
      commit("mergePolledAudioItems", { audioItems: newAudioItems, sortSpecs });
    }
  } catch (error) {
    console.error(error);
  } finally {
    nextPoll = setTimeout(
      () =>
        pollData({
          filterRules,
          view,
          sortSpecs,
          searchContinuationFields,
          fragmentSize,
          commit,
          rootGetters,
          rootState
        }),
      pollInterval
    );
  }
};

export const actions: ActionTree<AudioItemsState, RootState> = {
  fetchAudioitems: async (
    { commit, state, rootGetters, rootState },
    { authJWT, view = "long", sortSpecs, searchContinuationFields, filterRules }
  ) => {
    try {
      if (nextPoll) {
        clearTimeout(nextPoll);
      }
      const { fragmentSize } = state;
      commit("setIsLoading", true);
      const data = await queryAudioItems({
        filterRules,
        sortSpecs,
        authJWT,
        view,
        searchContinuationFields,
        fragmentSize
      });
      commit("setIsLoading", false);

      // All "new" searches reset the audioitems list, otherwise append for infinite scrolling
      if (!searchContinuationFields) {
        commit("setAudioitems", data.audioItems);
      } else {
        commit("appendAudioitems", data.audioItems);
      }

      commit(
        "setSearchContinuationFields",
        data.searchContinuationFields || null
      );

      commit("setTotalResults", data.totalResults);

      // Loading was successful, create a new invocation
      if (stageconfig.state.config.features.enablePolling) {
        nextPoll = setTimeout(
          () =>
            pollData({
              filterRules,
              view,
              sortSpecs,
              searchContinuationFields,
              fragmentSize,
              commit,
              rootGetters,
              rootState
            }),
          pollInterval
        );
      }
    } catch (error) {
      console.error(error);
      commit("setIsLoading", false);
    }
  },

  fetchAudioitemByGuid: async (
    { commit },
    { authJWT, view = "long", guid, filterRules }
  ) => {
    try {
      commit("setIsDetailLoading", true);
      const guidList = [guid];
      const { audioItems } = await queryAudioItemsForGuid({
        view,
        guid: guidList,
        authJWT,
        filterRules
      });
      const [audioItem] = audioItems;

      commit("setActiveAudioitem", audioItem);
      commit("setIsDetailLoading", false);
    } catch (error) {
      console.error(error);
      commit("setIsDetailLoading", false);
    }
  },
  deleteAudioitemByGuid: async ({ commit, state }, { authJWT, guid }) => {
    try {
      await axios.post("/audioitems/import/deleteFromIndex", [guid], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authJWT}`,
        }
      });
      commit("deleteAudioitem", guid);
      commit("setTotalResults", state.audioitems.length);
    } catch (error) {
      console.error(error);
    }
  },
  saveAudioitemByGuid: async ({ commit }, { authJWT, audioObject }) => {
    try {
      await patch({
        url: `audioitems/${audioObject.guid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authJWT}`,
        },
        payload: audioObject
      });
      commit("updateAudioItem", audioObject);
    } catch (error) {
      console.error(error);
    }
  },
  uploadAudioitem: async (_ctx, { authJWT, audioObject }) => {
    try {
      await axios.post("/audioitems/import/audioItemUpload", audioObject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authJWT}`,
        }
      });
    } catch (error) {
      console.error(error);
    }
  },
  toggleFavorite: async ({ commit }, { authJWT, audioItem }) => {
    const guid = audioItem.guid;
    const payload = {
      guid: guid,
      isFavorite: !audioItem.isFavorite
    };
    await axios.put(`/audioitems/api/v1/${guid}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authJWT}`
      }
    });
    audioItem.isFavorite = !audioItem.isFavorite;
    commit("updateAudioItem", audioItem);
  },
  toggleBulkFavorite: async ({ commit }, { authJWT, audioItems }) => {
    const favorites = audioItems.map(item => {
      return {
        audioItemGuid: item.audioItemGuid,
        isFavorite: item.isFavorite
      };
    });

    const payload = {
      favorites: favorites
    };

    await axios.put(`/audioitems/api/v1/favorites`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authJWT}`
      }
    });
    audioItems.forEach(({ audioItemGuid, isFavorite }) => {
      commit("updateAudioItem", { guid: audioItemGuid, isFavorite });
    });
  }
};
