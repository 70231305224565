/* eslint-disable no-console */

import { scaffoldActions } from "undo-redo-vuex";
import get from "lodash.get";
import api from "@/api";
import { getFilterIndexById } from "./getters";

const filtersServiceURL = "filters";

export const formatFilterRules = ({ filterRules }) =>
  filterRules?.map(filterRule => {
    const result = {
      ...filterRule
    };

    // Remove default optional properties, e.g. "fields", "exclude"

    if (filterRule.fields && !filterRule.fields.length) {
      delete result.fields;
    }

    if (filterRule.exclude !== undefined && !filterRule.exclude) {
      delete result.exclude;
    }

    return result;
  });

export const searchCuratedSearchNames = async ({ authJWT, search }) => {
  try {
    const { _embedded } = await api.post({
      url: `${filtersServiceURL}/search/names`,
      authJWT,
      payload: {
        search,
        page: 0,
        size: 100
      }
    });

    return (_embedded?.filter || [])
      .filter(
        ({ curatedSearch, isCuratedSearchActive }) =>
          curatedSearch === true && isCuratedSearchActive === true
      )
      .map(data => ({
        ...data,
        id: data._links?.self?.href?.split("/").pop()
      }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

// NB: API calls to filters service
// REVIEW: Can we get around string manipluation for building ids?
export const getFilters = ({ authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .get({
        url: `${filtersServiceURL}?sort=order&size=1000`,
        authJWT,
      })
      .then(res => res._embedded.filter) // eslint-disable-line
      .then(filters =>
        resolve(
          filters
            // NB: FE app requires the id property for routing/navigations
            .map(filter => ({
              ...filter,
              filterRules: formatFilterRules(filter),
              id: get(filter, "_links.self.href")
                ? filter._links.self.href.split("/").pop() // eslint-disable-line
                : ""
            }))
        )
      )
      .catch(err => reject(err))
  );

const saveFilter = ({ filter, authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .post({
        url: filtersServiceURL,
        payload: filter,
        authJWT,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

const updateFilter = ({ filter, authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .patch({
        url: `${filtersServiceURL}/${filter.id}`,
        payload: filter,
        authJWT,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

const deleteFilter = ({ filter, authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .delete({
        url: `${filtersServiceURL}/${filter.id}`,
        authJWT,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

export default scaffoldActions({
  async fetchFilters({ commit }, { authJWT }) {
    try {
      const allFilters = await getFilters({ authJWT });

      // NB: Grouped ordering of filters (i.e. user filters before agenda (public) filters)
      const userFilters = allFilters.filter(
        ({ isPublic }) => isPublic === false
      );
      const agendaFilters = allFilters.filter(
        ({ isPublic }) => isPublic === true
      );
      const filters = [...userFilters, ...agendaFilters];

      commit("setFilters", {
        filters
      });
      // await Vue.nextTick();
      commit("setInitialLoad");
    } catch (error) {
      console.warn(error);
      commit("setFilters", {
        filters: []
      });
    }
  },

  // NB: Action commits the addFilter mutation optimistically,
  // performs undo on error
  async saveFilter({ commit, dispatch }, { filter, authJWT }) {
    try {
      if (!filter.isShared) {
        commit("addFilter", {
          filter,
          authJWT,
          redoCallback: "apiSaveFilter",
          undoCallback: "apiDeleteFilter"
        });
      }
      await dispatch("apiSaveFilter", { filter, authJWT });
    } catch (error) {
      console.log("cannot save!!!!");
      dispatch("undo");
      commit("setErrorStatus", { error });
    }
  },

  // NB: This is the action which calls the API endpoint
  async apiSaveFilter({ state, commit }, { filter, authJWT }) {
    // eslint-disable-next-line no-useless-catch
    try {
      commit("setIsLoadingStatus");

      // NB: The new filter in the store has to be updated with the self._link
      // after it is persisted in the BE
      const savedFilter = await saveFilter({ filter, authJWT });
      if (!filter.isShared) {
        const filterIndex = state.filters.findIndex(
          ({ name }) => name === filter.name
        );
        commit("setFilter", {
          index: filterIndex,
          filter: {
            ...filter,
            ...savedFilter
          }
        });
        commit("setLoadedStatus");
      }
    } catch (error) {
      throw error;
    }
  },

  async deleteFilter({ commit, dispatch }, { filter, authJWT }) {
    try {
      commit("removeFilter", {
        filter,
        authJWT,
        redoCallback: "apiDeleteFilter",
        undoCallback: "apiSaveFilter"
      });
      await dispatch("apiDeleteFilter", { filter, authJWT });
    } catch (error) {
      console.warn(error);
      dispatch("undo");
      commit("setErrorStatus", { error });
    }
  },

  async apiDeleteFilter({ commit }, { filter, authJWT }) {
    commit("setIsLoadingStatus");
    await deleteFilter({ filter, authJWT });
    commit("setLoadedStatus");
  },

  // [1] REVIEW: Should the action have knowledge of getters?
  updateFilter: async ({ state, commit, dispatch }, { filter, authJWT }) => {
    const { id } = filter;
    const index = getFilterIndexById(state)(id); /* 1. */
    commit("setIsLoadingStatus");
    commit("setIsSaved", false);

    if (index > -1) {
      commit("updateFilter", { index, filter });
      commit("setIsSaved", true);
      commit("setLoadedStatus");
    }

    try {
      await dispatch("apiUpdateFilter", {
        filter,
        authJWT,
        redoCallback: "apiUpdateFilter",
        undoCallback: "apiUpdateFilter"
      });
    } catch (error) {
      console.warn(error);
      dispatch("undo");
      commit("setErrorStatus", { error });
    }
  },

  async apiUpdateFilter({ commit }, { filter, authJWT }) {
    commit("setIsLoadingStatus");
    await updateFilter({ filter, authJWT });
    commit("setLoadedStatus");
  },

  /**
   * Updates the filter visbility optimistically and dispatches the update to
   * the API.
   *
   * TODO: Remove repetitive code bits – compare with updateFilter.
   *
   * @param  {String}   options.id
   * @param  {Boolean}  options.isVisible
   * @param  {String}   options.authJWT
   */
  updateFilterIsVisible: async (
    { state, commit, dispatch },
    { id, isVisible, authJWT }
  ) => {
    const index = getFilterIndexById(state)(id);

    if (index > -1) {
      commit("setFilterIsVisible", { id, isVisible });
    }

    try {
      await dispatch("apiUpdateFilter", {
        filter: {
          id,
          isVisible
        },
        authJWT
      });
    } catch (error) {
      console.warn(error);
      commit("setErrorStatus", { error });
    }
  },

  /**
   * Updates the activity/publish state of a curated search and dispatches the update to
   * the API.
   *
   * @param  {String}   options.id
   * @param  {Boolean}  options.isCuratedSearchActive
   * @param  {String}   options.authJWT
   */
  updateCuratedSearchIsActive: async (
    { state, commit, dispatch },
    { id, isCuratedSearchActive, authJWT }
  ) => {
    const index = getFilterIndexById(state)(id);

    if (index > -1) {
      commit("setCuratedSearchIsActive", { id, isCuratedSearchActive });
    }

    try {
      await dispatch("apiUpdateFilter", {
        filter: {
          id,
          isCuratedSearchActive
        },
        authJWT
      });
    } catch (error) {
      console.warn(error);
      commit("setErrorStatus", { error });
    }
  },

  /**
   * Updates the filter notification settings optimistically and dispatches
   * the update to the API.
   *
   * @param  {String}   options.id
   * @param  {Boolean}  options.isNotificationEnabled
   * @param  {String}   options.authJWT
   */
  updateFilterIsNotificationEnabled: async (
    { state, commit, dispatch },
    { id, isNotificationEnabled, isPushEnabled, authJWT }
  ) => {
    const index = getFilterIndexById(state)(id);

    if (index > -1) {
      commit("setFilterIsEmailNotificationEnabled", {
        id,
        isNotificationEnabled
      });
      commit("setFilterIsPushNotificationEnabled", { id, isPushEnabled });
    }

    try {
      await dispatch("apiUpdateFilter", {
        filter: {
          id,
          isNotificationEnabled,
          isPushEnabled
        },
        authJWT
      });
    } catch (error) {
      console.warn(error);
      commit("setErrorStatus", { error });
    }
  },

  updateCuratedSearchOrder({ dispatch }, { curatedSearches, authJWT }) {
    curatedSearches.forEach(({ id }, index) => {
      dispatch("updateFilter", {
        filter: {
          id,
          order: index
        },
        authJWT
      });
    });
  },
  getSharedSearch: async (ctx, { sharedSearchId, authJWT }) => {
    try {
      return await api.get({
        url: `${filtersServiceURL}/${sharedSearchId}`,
        authJWT,
      });
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
});
